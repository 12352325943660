import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			deviceType: 'mobile',
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5103211735042916',
				ad1: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "6955171686",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				},
				ad2: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "3015926678",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				},
				ad3: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "6252464109",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				},
				ad4: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "8486140542",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				},
				ad5: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "4939382434",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				},
				ad6: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "6929516295",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				}
			}
			,
			showDebug: false,
			firebaseConfig: {
				apiKey: 'AIzaSyDPCzERUQwfWUSnOMxfKN_l1JI8B5jvPl0', 
				authDomain: 'web-a2bf7.firebaseapp.com', 
				projectId: 'web-a2bf7',
				storageBucket: 'web-a2bf7.appspot.com', 
				messagingSenderId: '780267482533', 
				appId: "1:780267482533:web:fe032b948603fb99bf0c4c",
				measurementId: 'G-054Z6V4DLZ',
			}
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
		},
	})
}
